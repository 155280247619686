@import url('https://fonts.googleapis.com/css2?family=Bona+Nova+SC:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.background-image {
  background-image: url('../public/Image/cricket_stadium.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 90vh;
}

.background-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../public/Image/cricket_stadium.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* Add this line to ensure no repeating */
  filter: blur(20px);
  z-index: -1;
  /* Ensures the pseudo-element is behind the content */
}

.headerimage {
  background-image: url('../public/Image/cricket_stadium.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
}

.header-image-container {
  width: 100%;
  overflow: hidden;
}

.content {
  position: relative;
  z-index: 1;
  /* Ensures content is above the pseudo-element */
  color: white;
  text-align: center;
  padding: 20px;
}

.gold-fill {
  fill: gold;
}

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}

.anton-regular {
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.h-custom {
  height: calc(100% - 73px);
}

@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.bona-nova-sc-bold {
  font-family: "Bona Nova SC", serif;
  font-weight: 700;
  font-style: normal;
}

.playfair-display-sc-regular {
  font-family: "Playfair Display SC", serif;
  font-weight: 400;
  font-style: normal;
}

.playfair-display-sc-bold {
  font-family: "Playfair Display SC", serif;
  font-weight: 700;
  font-style: normal;
}

.playfair-display-sc-black {
  font-family: "Playfair Display SC", serif;
  font-weight: 900;
  font-style: normal;
}

.form-gap {
  padding-top: 70px;
}

.height-100 {
  height: 100vh
}

.card {
  width: 400px;
  border: none;
  height: 400px;
  box-shadow: 0px 5px 20px 0px #d2dae3;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center
}

.card h6 {
  color: black;
  font-size: 20px
}

.inputs input {
  width: 40px;
  height: 40px
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0
}

.card-2 {
  background-color: #fff;
  padding: 10px;
  width: 350px;
  height: 100px;
  bottom: -50px;
  left: 20px;
  position: absolute;
  border-radius: 5px
}

.card-2 .content {
  margin-top: 50px
}

.card-2 .content a {
  color: black
}

.form-control:focus {
  box-shadow: none;
  border: 2px solid black
}

.validate {
  border-radius: 20px;
  height: 40px;
  background-color: black;
  border: 1px solid white;
  width: 140px
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.date-label {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.date-input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.date-input:focus {
  border-color: #007bff;
}

/* ChatBox.css */
.chat-box {
  border: 1px solid #ccc;
  padding: 10px;
  max-height: 80.36vh;
  height: 80.36vh;
  overflow-y: auto;
}

.message {
  display: flex;
  margin-bottom: 10px;
}

.message.left {
  justify-content: flex-start;
}

.message.right {
  justify-content: flex-end;
}

.message-content-left {
  padding: 10px;
  border-radius: 0px 10px 10px 10px;
}

.message-content-right {
  max-width: 60%;
  padding: 10px;
  border-radius: 10px 0px 10px 10px;
}

.message.left .message-content-left {
  background-color: #373737;
  text-align: left;
  color: white;
}

.message.right .message-content-right {
  background-color: #203f31;
  text-align: right;
  color: white;
}

.dropdown-toggle::after {
  display: none;
}


/* From here the responsiveness starts */
.features-card {
  width: 19rem;
  border-radius: 15px;
  height: 320px;
}

.features-row {
  overflow-x: auto;
  height: 415px;
  flex-wrap: nowrap;
}

.top-team-row {
  overflow-x: auto;
  height: 375px;
  flex-wrap: nowrap;
}

.hero-button {
  width: 20%;
  border-radius: 20px;
}

.mini-stat {
  width: 45%;
  height: 90px;
}

.login-form {
  width: 60%;
  border-radius: 15px;
  box-shadow: 0 0 9px 1px grey;
}

.register-form {
  width: 80%;
  border-radius: 15px;
  box-shadow: 0 0 9px 1px grey;
}

.dashboard-container {
  margin-bottom: 0px;
}

.chat-sidebar {
  width: 35%;
  background-color: #f0f0f0;
  padding: 20px;
}

.chat-chatbox {
  width: 65%;
}

.chat-box-background {
  background-image: url('../public/Image/Cricket_Wallpaper5.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  /* height: 100%; */
}

.chat-triple-dot {
  width: 40%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.chat-profile {
  width: 7%;
  display: flex;
  align-items: center;
}

.chat-emoji {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.chat-send {
  display: flex;
  align-items: center;
}

.friend-list-search {
  display: block;
}

.friend-list-searchBar {
  display: flex;
  justify-content: end;
}

.sticky-button {
  position: absolute;
    bottom: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    z-index: 10;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.bottomnavbar-active {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0d6efd;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.notification-btn {
  width: 20%;
}

.chat-box-header {
  background-color: rgb(92 92 93);
  height: 10%;
}

.dropdown-menu-up {
  top: -135px !important;
  left: -30px !important;
}

.dropdown-menu {
  min-width: 5rem !important;
}

.line {
  flex-grow: 1;
  height: 1px;
  background-color: gray;
  margin: 0 10px;
}

.custom-accordion .accordion-button::after {
  display: none !important;
}

.coin_selection {
  width: 30%;
}

.coin {
  color: #977f05;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 7px solid #977f01;
  box-shadow: 0px 0px 10px 5px grey;
  background: gold;
  text-align: center;
  line-height: 100px;
  font-size: 24px;
  margin: 20px auto;
  transform-style: preserve-3d;
  transition: transform 2s ease;
}

.flipping {
  animation: flipAndGrowUpwards 2s ease forwards;
}

.hover-effect:hover {
  background-color: #747576;
  /* Light background on hover */
  cursor: pointer;
  /* Hand cursor on hover */
}

/* Keyframes for flipping, growing, and moving upwards */
@keyframes flipAndGrowUpwards {
  0% {
    transform: rotateX(0) translateY(0) scale(1);
  }

  10% {
    transform: rotateX(180deg) translateY(0) scale(1.1);
  }

  30% {
    transform: rotateX(360deg) translateY(0) scale(1.3);
  }

  50% {
    transform: rotateX(540deg) translateY(0) scale(1.5);
    /* Coin grows and moves up */
  }

  100% {
    transform: rotateX(720deg) translateY(0) scale(1);
    /* Coin returns to original size */
  }
}




.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Faded background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it's on top */
}

.hidden {
  display: none;
  /* Hide the overlay when not visible */
}

.animation-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Toastify__toast.custom-success-toast {
  background-color: #198754 !important;
  color: white !important;
}

.Toastify__toast.custom-danger-toast {
  background-color: rgb(145, 8, 8) !important;
  color: white !important;
}

.Toastify__toast {
  background-color: rgb(145, 8, 8) !important;
  color: white;
  /* Optional: Adjust text color for better contrast */
}

.Toastify__close-button--light {
  color: white !important;
}

.Toastify__progress-bar-theme--light {
  background: white;
}

/* Customize the close button (cross icon) color */
.Toastify__close-button {
  color: white !important;
}

.current-over-item {
  flex: 0 0 45px;
  /* Prevent shrinking and ensure consistent width */
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

/* For Mobile Screen */
@media only screen and (max-width: 767px) {
  .coin_selection {
    width: 60%;
  }

  .features-card {
    width: 12rem;
    height: 320px;
  }

  .features-row {
    overflow-x: auto;
    height: 375px;
    flex-wrap: nowrap;
  }

  .background-image {
    height: auto;
    /* Adjusts height to maintain aspect ratio */
  }

  .background-image::before {
    background-size: contain;
    background-position: top;
  }

  .hero-button {
    width: 30%;
  }

  .mini-stat {
    width: 85%;
    height: 90px;
  }

  .login-form {
    width: 100%;
  }

  .register-form {
    width: 100%;
  }

  .dashboard-container {
    margin-bottom: 50px;
  }

  .chat-sidebar {
    width: 100%;
  }

  .chat-chatbox {
    width: 100%;
  }

  .chat-box {
    border: 1px solid #ccc;
    padding: 10px;
    max-height: 90vh;
    height: calc(90vh - 100px);
    overflow-y: auto;
  }

  .chat-triple-dot {
    width: 25%;
  }

  .chat-profile {
    width: 15%;
  }

  .chat-emoji {
    display: flex;
    padding: 0;
    padding-left: 10px;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .chat-send {
    padding: 0;
  }

  .friend-list-search {
    display: none;
  }

  .friend-list-searchBar {
    display: flex;
    justify-content: center;
  }

  .notification-btn {
    width: 30%;
  }

  .chat-box-header {
    background-color: rgb(92 92 93);
    padding: 15px;
    height: auto;
  }

  .chat-box-footer {
    position: fixed;
    bottom: 50px;
    padding-right: 10px;
    padding-left: 10px;
    left: 0;
    right: 0;
    z-index: 1;
  }

}